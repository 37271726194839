import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen13 } from '../../../components/images/support/getting-started/Screen13.generated';
import { Screen14 } from '../../../components/images/support/getting-started/Screen14.generated';
import { Screen15 } from '../../../components/images/support/getting-started/Screen15.generated';
import { Screen16 } from '../../../components/images/support/getting-started/Screen16.generated';
import { Screen17 } from '../../../components/images/support/getting-started/Screen17.generated';
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "create-a-position"
    }}>{`Create a position`}</h1>
    <p>{`The next step in the getting started guide is to create a Position.`}</p>
    <ol>
      <li parentName="ol">{`Navigate to the position menu and click the `}<strong parentName="li">{`Create`}</strong>{` button.`}</li>
    </ol>

    <Screen13 mdxType="Screen13" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Give the position a name in this example we use `}<strong parentName="li"><em parentName="strong">{`Chef`}</em></strong>{`. Then click on the position.`}</li>
    </ol>

    <Screen14 mdxType="Screen14" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Assign`}</strong>{` button. This is where we assign the Workflows.`}</li>
    </ol>

    <Screen15 mdxType="Screen15" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Select the payroll Workflow that we created previously, then click `}<strong parentName="li">{`Submit`}</strong>{`.`}</li>
    </ol>

    <Screen16 mdxType="Screen16" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Great work 🙌. We can now see the Workflow has been assigned to the position.
Now any new employees that are chefs will have to complete this Workflow. The next step is inviting an employee.
Navigate to the user menu and click the next section to invite an employee users.`}</li>
    </ol>

    <Screen17 mdxType="Screen17" />
    <h3 {...{
      "id": "now-lets-invite-our-first-employee-to-the-platform-️"
    }}>{`Now lets invite our first employee to the platform 👷‍♀️`}</h3>

    <SupportFooter linkTo="/support/getting-started/invite-an-employee" linkText="Invite an employee" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      